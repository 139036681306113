<template lang="pug">
#app
  v-container(grid-list-xl, fluid)
    v-layout(v-if="$root.user.permissao != 0", row, wrap)
      AccessLock
    v-layout(v-if="$root.user.permissao == 0", row, wrap)
      v-flex(lg12)
        v-card
          v-toolbar(card, color='white')
            v-text-field.hidden-sm-and-down(flat, solo, prepend-icon='search', placeholder='Buscar categoria de questão', v-model='search', hide-details)
            v-switch.mt-4(:false-value="0", :true-value="1", :color="filter.ativo ? 'success': ''", :label="filter.ativo ? 'Categoria de questões ativas' : 'Categoria de questões inativas'", v-model="filter.ativo")
            v-btn(color="bt-primary", @click="openDialogCategoriaQuestao()").white--text Cadastrar
          v-divider
          v-card-text.pa-0
            v-data-table.elevation-1(:headers='headers', :search='search', :items='list', :rows-per-page-items="[25,50,75,{text:'Todos','value':-1}]", :loading="loading")
              template(slot='items', slot-scope='props')
                td.text-xs-left {{ props.item.texto }}
                td.text-xs-left {{ props.item.questionario.nome }}
                td.text-xs-right
                  v-tooltip(top)
                    v-btn(slot="activator", flat, icon, fab, dark, color='bt-primary', small, @click="openDialogCategoriaQuestao(props.item)")
                      v-icon edit
                    span Editar
                  v-tooltip(top)
                    v-btn(slot="activator", flat, icon, fab, dark, :color="props.item.ativo ? 'red' : 'warning'", small, @click="changeAtivo(props.item)")
                      v-icon {{ props.item.ativo ? 'delete' : 'restore' }}
                    span {{ props.item.ativo ? 'Desativar' : 'Ativar' }}

    v-dialog(v-model="dialogCategoriaQuestao", width="auto", scrollable, max-width="800px", persistent, v-if="dialogCategoriaQuestao")
      v-card
        v-card-title
          span.headline {{ categoriaQuestaoAux.id ? 'Editar Categoria' : 'Cadastrar Categoria' }}
          v-spacer
          v-tooltip(top)
            v-btn(icon, slot="activator", @click.native="$validator.pause();  categoriaQuestaoAux = {}; dialogCategoriaQuestao = false;")
              v-icon close
            span Fechar
        v-card-text
          form(@submit.prevent="salvar")
            v-layout(row, wrap)
              v-flex.md12.xs12
                v-text-field(label="Nome", color="gray", required, v-validate="'required'", :error-messages="errors.collect('texto')", data-vv-name="texto", data-vv-as="Texto", type="text", v-model='categoriaQuestaoAux.texto')
            v-layout(row, wrap)
              v-flex.md12.xs12
                v-autocomplete(label="Selecione um questionário", :items="listQuestionarios", :disabled="!listQuestionarios.length", item-text="nome", item-value="id", color="gray", v-model="categoriaQuestaoAux.questionario_id", :loading="loadingQuestionarios", :clearable="true", autocomplete, :filter="customFilter", required, v-validate="'required'")
            v-layout(row, wrap)
              v-flex.xs12.text-xs-right
                v-btn(type="submit", color="bt-primary", :loading='loading').white--text Salvar

</template>

<script>
import _ from 'lodash';
import AccessLock from '@/components/AccessLock';

export default {
  components: {
    AccessLock
  },
  data: () => ({
    filter: { ativo: 1 },
    categorias_questoes: [],
    listQuestionarios: [],
    headers: [
      { text: 'Texto', align: 'left', value: 'texto' },
      { text: 'Questionário', align: 'left', value: 'questionario.nome' },
      { text: 'Ações', align: 'right', sortable: false },
    ],
    search: '',
    loading: false,
    loadingQuestionarios: false,
    dialogCategoriaQuestao: false,
    categoriaQuestaoAux: {},
  }),
  computed: {
    list () {
      return _.filter(this.categorias_questoes, { ativo: this.filter.ativo });
    }
  },
  mounted () {
    this.getCategoriasQuestoes();
  },
  methods: {
    async getCategoriasQuestoes () {
      const vm = this;
      try {
        vm.loading = true;
        const response = await vm.$axios.get('/categoria-questao', { params : { fields: 'questionario'} });
        vm.categorias_questoes = response.data.rows;
        vm.loading = false;
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao buscar os dados. Atualize a página!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
        vm.loading = false;
      }
    },
    async changeAtivo (categoria_questao) {
      const vm = this;
      try {
        const obj = _.cloneDeep(categoria_questao);
        obj.ativo = obj.ativo ? 0 : 1;
        const response = await vm.$axios.put(`/categoria-questao/${obj.id}`, obj);
        categoria_questao.ativo = response.data.ativo;
        window.getApp.$emit('APP_ALERT', { color: obj.ativo ? 'success' : 'orange', text: `Categoria de questão ${categoria_questao.ativo ? 'ativada' : 'desativada'} com sucesso!` });
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao salvar a categoria de questão. Tente novamente!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
      }
    },
    async save (categoria_questao) {
      const vm = this;
      try {
        const response = await vm.$axios.post('/categoria-questao', categoria_questao);
        categoria_questao.id = response.data.id;
        response.data.questionario = _.find(vm.listQuestionarios, { id: response.data.questionario_id });
        vm.categorias_questoes.push(response.data);
        vm.loading = false;
        vm.dialogCategoriaQuestao = false;
        vm.categoriaQuestaoAux = {};
        window.getApp.$emit('APP_ALERT', { color: 'success', text: 'Categoria de questão salva com sucesso!' });
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao salvar a categoria de questão. Tente novamente!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
        vm.loading = false;
      }
    },
    async update (categoria_questao) {
      const vm = this;
      try {
        if (categoria_questao.QuestionarioId) delete categoria_questao.QuestionarioId;
        const response = await vm.$axios.put(`/categoria-questao/${categoria_questao.id}`, categoria_questao);
        response.data.questionario = _.find(vm.listQuestionarios, { id: response.data.questionario_id });
        const index = _.findIndex(vm.categorias_questoes, { id: categoria_questao.id });
        const categorias_questoes = _.reject(vm.categorias_questoes, { id: categoria_questao.id });
        vm.categorias_questoes = [...categorias_questoes.slice(0, index), response.data, ...categorias_questoes.slice(index)];
        vm.loading = false;
        vm.dialogCategoriaQuestao = false;
        vm.categoriaQuestaoAux = {};
        window.getApp.$emit('APP_ALERT', { color: 'success', text: 'Categoria de questão salvo com sucesso!' });
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao atualizar a categoria de questão. Tente novamente!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
        vm.loading = false;
      }
    },
    async salvar (scope) {
      const vm = this;
      try {
        vm.loading = true;  
        const result = await vm.$validator.validateAll();
        if (!result) throw 'Preencha todos os campos corretamente!';
        vm[vm.categoriaQuestaoAux.id ? 'update' : 'save'](vm.categoriaQuestaoAux);
        vm.$validator.reset();
      } catch (error) {
        window.getApp.$emit('APP_ALERT', { color: 'red', text: error });
        vm.loading = false;
      }
    },
    async openDialogCategoriaQuestao(categoria) {
      const vm = this;
      try {
        if (categoria && categoria.id) {
          vm.categoriaQuestaoAux = _.cloneDeep(categoria);
          const response = await vm.$axios.get(`/categoria-questao/${vm.categoriaQuestaoAux.id}`, { params : { fields: 'questionario'} });
          vm.categoriaQuestaoAux = response.data || {};
        }
        vm.getQuestionarios();
        vm.dialogCategoriaQuestao = true;
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao buscar os dados. Atualize a página!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
      }
    },
    async getQuestionarios () {
      let vm = this;
      try {
        const response = await vm.$axios.get(`/questionario`);
        vm.loadingQuestionarios = true;
        vm.listQuestionarios = response.data.rows;
      } catch (error) {
        vm.loadingQuestionarios = true;
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao buscar os dados. Atualize a página!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
      }
    },
    customFilter (item, queryText, itemText) {
      const hasValue = val => val != null ? val : '';
      const text = hasValue(item.nome);
      const query = hasValue(queryText);
      return text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) > -1
    },
  }
};
</script>

<style scoped>

</style>

